import Vue from 'vue'
import Router from "vue-router";

Vue.use(Router)

function guardMyroute(to, from, next) {
  var isAuthenticated = false;
  if (localStorage.getItem('user')) {
    isAuthenticated = true;
  }
  else if (localStorage.getItem('customer')) {
    isAuthenticated = true;
  }
  else {
    isAuthenticated = false;
  }
  if (isAuthenticated) {
    next(); // allow to enter route
  }
  else {
    next('/'); // go to '/login';
  }
}

export default new Router({
  scrollBehavior() {
    return window.scrollTo({ top: 0, behavior: "smooth" });
  },
  // mode: 'history',
  routes: [
    {
      path: "/",
      name: "/",
      meta: { layout: "userpages" },
      component: () => import("../Frontend/Pages/HomePage/Index.vue")
    },
    //Admin Routes start- Register-Login Pages 
    {
      path: "/login",
      name: "login",
      meta: { layout: "userpages" },
      component: () => import("../Admin/UserPages/Login.vue")
    },
    {
      path: "/register-user",
      name: "register-user",
      meta: { layout: "userpages" },
      component: () => import("../Admin/UserPages/Register.vue")
    },
    {
      path: "/register",
      name: "register",
      meta: { layout: "userpages" },
      component: () => import("../Admin/UserPages/OtpValidation.vue")
    },
    {
      path: "/forgot_password",
      name: "forgot_password",
      meta: { layout: "userpages" },
      component: () => import("../Admin/UserPages/ForgotPassword.vue")
    },
    {
      path: '/reset_password_form',
      name: 'reset_password_form',
      beforeEnter: guardMyroute,
      component: () =>
        import('../Admin/UserPages/ResetPasswordForm.vue'),
      meta: { layout: 'userpages' },
    },
    {
      path: "/reset_password",
      name: "reset_password",
      beforeEnter: guardMyroute,
      component: () => import("../Admin/UserPages/ResetPassword.vue")
    },

    //After Login Pages

    //Dashboard
    {
      path: "/dashboard",
      name: "dashboard",
      beforeEnter: guardMyroute,
      component: () => import("../Admin/Pages/Dashboard.vue")
    },

    //Roles
    {
      path: "/roles",
      name: "roles",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/Role/Index.vue"),
    },
    {
      path: "/roles_amend",
      name: "roles_amend",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/Role/RolesAmend.vue"),
    },
    {
      path: "/roles_menu",
      name: "roles_menu",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/Role/RoleMenu/MenuAccess")
    },

    //Menus
    {
      path: "/menus",
      name: "menus",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/Menu/Index.vue"),
    },
    {
      path: "/menu_amend",
      name: "menu_amend",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/Menu/MenusAmend.vue"),
    },

    //Lookups
    {
      path: "/lookups",
      name: "lookups",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/Lookups/Index.vue"),
    },
    {
      path: "/lookups_amend",
      name: "lookups_amend",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/Lookups/LookupsAmend.vue"),
    },
    {
      path: "/child_lookups",
      name: "child_lookups",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/Lookups/ChildIndex.vue"),
    },
    {
      path: "/system_parameter",
      name: "system_parameter",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/SystemParameter/Index.vue"),
    },
    {
      path: "/system_parameter_amend",
      name: "system_parameter_amend",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/SystemParameter/SystemParameterAmend.vue"),
    },
    {
      path: "/email_template",
      name: "email_template",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/EmailTemplates/Index.vue"),
    },
    {
      path: "/email_template_amend",
      name: "email_template_amend",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/EmailTemplates/EmailTemplateAmend.vue"),
    },

    //Users
    {
      path: "/users",
      name: "users",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/Users/Index.vue")
    },
    {
      path: "/users.amend",
      name: "users.amend",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/Users/UsersAmend.vue")
    },
    {
      path: "/users.view",
      name: "users.view",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/Users/UserView.vue")
    },

    //countries
    {
      name: "countries",
      path: "/countries",
      beforeEnter: guardMyroute,
      component: () =>
        import(
          "../Admin/Pages/Configuration/Countries/CountriesIndex.vue"
        )
    },
    {
      name: "countries.amend",
      path: "/countries.amend",
      beforeEnter: guardMyroute,
      component: () =>
        import(
          "../Admin/Pages/Configuration/Countries/CountriesAmend.vue"
        )
    },
    {
      name: "states",
      path: "/states",
      beforeEnter: guardMyroute,
      component: () =>
        import(
          "../Admin/Pages/Configuration/Countries/StatesIndex.vue"
        )
    },
    {
      name: "states.amend",
      path: "/states.amend",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/Countries/StatesAmend.vue")
    },
    {
      name: "cities",
      path: "/cities",
      beforeEnter: guardMyroute,
      component: () =>
        import(
          "../Admin/Pages/Configuration/Countries/CitiesIndex.vue"
        )
    },
    {
      name: "cities.amend",
      path: "/cities.amend",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/Countries/CitiesAmend.vue")
    },
    //Subscribers
    {
      name: "subscribers",
      path: "/subscribers",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/Subscribers/Index.vue")
    },

    //Terms & Conditions
    {
      name: "terms&conditions",
      path: "/terms&conditions",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/TermsAndConditions/Index.vue")
    },
    {
      path: "/terms_and_conditions.amend",
      name: "terms_and_conditions.amend",
      beforeEnter: guardMyroute,
      component: () => import("../Admin/Pages/Configuration/TermsAndConditions/TermsAndConditionsAmend.vue")
    },
    //faq create and amend
    {
      name: "faqs",
      path: "/faqs",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/FAQ/Index.vue")
    },
    {
      path: "/faq.amend",
      name: "faq.amend",
      beforeEnter: guardMyroute,
      component: () => import("../Admin/Pages/Configuration/FAQ/FAQAmend.vue")
    },

    //Add-on's
    {
      name: "add-on",
      path: "/add-on",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/AddOnMaster/index.vue")
    },
    {
      name: "add-on.amend",
      path: "/add-on.amend",
      beforeEnter: guardMyroute,
      component: () =>
        import(
          "../Admin/Pages/Configuration/AddOnMaster/AddonAmend.vue"
        )
    },
    {
      name: "add-on-pricing",
      path: "/add-on-pricing",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/AddOnMaster/AddonPricing.vue")
    },
    //contact branch create and amend
    {
      name: "contact_branches",
      path: "/contact_branches",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/ContactBranches/Index.vue")
    },
    {
      name: "contact_branches.amend",
      path: "/contact_branches.amend",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/ContactBranches/ContactBranchesAmend.vue")
    },
    //Template-master
    {
      name: "template-master",
      path: "/template-master",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/TemplateMaster/index.vue")
    },
    {
      name: "template-master.amend",
      path: "/template-master.amend",
      beforeEnter: guardMyroute,
      component: () =>
        import(
          "../Admin/Pages/Configuration/TemplateMaster/TemplateMasterAmend.vue"
        )
    },
    //survey report and amend
    {
      name: "survey-report",
      path: "/survey-report",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/Survey/Index.vue")
    },
    {
      name: "survey-report-amend",
      path: "/survey-report-amend",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/Survey/SurveyQuestionAmend.vue")
    },
    //Vehicle-Make
    {
      name: "vehicle-make",
      path: "/vehicle-make",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/VehicleMake/index.vue")
    },
    {
      name: "vehicle-make.amend",
      path: "/vehicle-make.amend",
      beforeEnter: guardMyroute,
      component: () =>
        import(
          "../Admin/Pages/Configuration/VehicleMake/VehicleMakeAmend.vue"
        )
    },

    //Customers
    {
      path: "/customers",
      name: "customers",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/Customers/Index.vue")
    },
    {
      path: "/customers.amend",
      name: "customers.amend",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/Customers/CustomerAmend.vue")
    },

    //Vehicle-Model
    {
      name: "vehicle-model",
      path: "/vehicle-model",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/VehicleModel/index.vue")
    },
    {
      name: "vehicle-model.amend",
      path: "/vehicle-model.amend",
      beforeEnter: guardMyroute,
      component: () =>
        import(
          "../Admin/Pages/Configuration/VehicleModel/VehicleModelAmend.vue"
        )
    },
    {
      name: "vehicle-model-addon-pricing",
      path: "/vehicle-model-addon-pricing",
      beforeEnter: guardMyroute,
      component: () =>
        import(
          "../Admin/Pages/Configuration/VehicleModel/VehicleModelAddonPricing.vue"
        )
    },
    //Vehicle Counters
    {
      name: "vehiclecounter",
      path: "/vehiclecounter",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/VehicleCounters/Index.vue")
    },
    {
      name: "vehiclecounter.amend",
      path: "/vehiclecounter.amend",
      beforeEnter: guardMyroute,
      component: () =>
        import(
          "../Admin/Pages/Configuration/VehicleCounters/VehicleCountersAmend.vue"
        )
    },
    {
      name: "specialcounter",
      path: "/specialcounter",
      beforeEnter: guardMyroute,
      component: () =>
        import(
          "../Admin/Pages/Configuration/VehicleCounters/SpecialCounterTimings.vue"
        )
    },
    {
      name: "specialcounter.amend",
      path: "/specialcounter.amend",
      beforeEnter: guardMyroute,
      component: () =>
        import(
          "../Admin/Pages/Configuration/VehicleCounters/SpecialCounterTimingsAmend.vue"
        )
    },

    //KYC
    {
      path: "/kyc",
      name: "kyc",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/KYC/Index.vue")
    },
    {
      path: "/kyc.amend",
      name: "kyc.amend",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/KYC/KYCAmend.vue")
    },
    {
      path: "/kyc.view",
      name: "kyc.view",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/KYC/KYCView.vue")
    },
    {
      path: "/kyc.expiry_details",
      name: "kyc.expiry_details",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/KYC/KYCExpiryDetails.vue")
    },

    //Attributes
    {
      path: "/attributes",
      name: "attributes",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/Attributes/Index.vue")
    },
    {
      path: "/attributes.amend",
      name: "attributes.amend",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/Attributes/AttributesAmend.vue")
    },
    //Attributes group
    {
      path: "/attribute-group",
      name: "attribute-group",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/AttributesGroup/Index.vue")
    },
    {
      path: "/attribute-group.amend",
      name: "attribute-group.amend",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/AttributesGroup/AttributesGroupAmend.vue")
    },
    //country detail report and create/amend
    {
      name: "country-detail",
      path: "/country-detail",
      beforeEnter: guardMyroute,
      component: () =>
        import(
          "../Admin/Pages/Configuration/Countries/CountryGroupIndex.vue"
        )
    },
    {
      name: "country-detail.amend",
      path: "/country-detail.amend",
      beforeEnter: guardMyroute,
      component: () =>
        import(
          "../Admin/Pages/Configuration/Countries/CountryGroupAmend.vue"
        )
    },
    //testimonials report
    {
      name: "testimonials",
      path: "/testimonials",
      beforeEnter: guardMyroute,
      component: () =>
        import(
          "../Admin/Pages/Configuration/Testimonials/Index.vue"
        )
    },
    //complaints and enquiry report
    {
      name: "customer-complaints-enquiry",
      path: "/customer-complaints-enquiry",
      beforeEnter: guardMyroute,
      component: () =>
        import(
          "../Admin/Pages/Configuration/ComplaintsEnquiry/Index.vue"
        )
    },
    //enquiry master report and amend
    {
      name: "view-complaints-enquiry-master",
      path: "/view-complaints-enquiry-master",
      beforeEnter: guardMyroute,
      component: () =>
        import(
          "../Admin/Pages/Configuration/ComplaintsEnquiry/EnquiryMasterIndex.vue"
        )
    },
    {
      name: "complaints-enquiry-master.amend",
      path: "/complaints-enquiry-master.amend",
      beforeEnter: guardMyroute,
      component: () =>
        import(
          "../Admin/Pages/Configuration/ComplaintsEnquiry/EnquiryMasterAmend.vue"
        )
    },
    //view the customer complaints
    {
      name: "complaints-enquiry-view",
      path: "/complaints-enquiry-view",
      beforeEnter: guardMyroute,
      component: () =>
        import(
          "../Admin/Pages/Configuration/ComplaintsEnquiry/ViewEnquiryDetails.vue"
        )
    },
    //delivery charges
    {
      name: "delivery-charges",
      path: "/delivery-charges",
      beforeEnter: guardMyroute,
      component: () =>
        import(
          "../Admin/Pages/Configuration/DeliveryCharges/Index.vue"
        )
    },
    {
      name: "delivery-charges.amend",
      path: "/delivery-charges.amend",
      beforeEnter: guardMyroute,
      component: () =>
        import(
          "../Admin/Pages/Configuration/DeliveryCharges/DeliveryChargesAmend.vue"
        )
    },
    //ServiceTypes
    {
      path: "/service-types",
      name: "servicetypes",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/ServiceTypes/Index.vue")
    },
    {
      path: "/service-types.amend",
      name: "servicetypes.amend",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/ServiceTypes/ServiceTypesAmend.vue")
    },

    //Drivers
    {
      name: "drivers",
      path: "/drivers",
      beforeEnter: guardMyroute,
      component: () =>
        import(
          "../Admin/Pages/Configuration/Drivers/Index.vue"
        )
    },
    {
      name: "drivers.amend",
      path: "/drivers.amend",
      beforeEnter: guardMyroute,
      component: () =>
        import(
          "../Admin/Pages/Configuration/Drivers/DriversAmend.vue"
        )
    },
    //Service Requests
    {
      path: "/service-requests",
      name: "servicerequests",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/ServiceRequests/Index.vue")
    },
    {
      path: "/service-requests.amend",
      name: "servicerequests.amend",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/ServiceRequests/ServiceRequestAmend.vue")
    },

    //Vehicle details
    {
      path: "/vehicle-details",
      name: "vehicle-details",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/VehicleDetails/Index.vue")
    },
    {
      path: "/vehicle-details.amend",
      name: "vehicle-details.amend",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/VehicleDetails/VehicleDetailsAmend.vue")
    },
    {
      path: "/vehicle-pricing.amend",
      name: "vehicle-pricing.amend",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/VehicleDetails/VehiclePricingAmend.vue")
    },
    //Promos
    {
      name: "promos",
      path: "/promos",
      beforeEnter: guardMyroute,
      component: () =>
        import(
          "../Admin/Pages/Configuration/PromoCode/Index.vue"
        )
    },
    {
      name: "promos.amend",
      path: "/promos.amend",
      beforeEnter: guardMyroute,
      component: () =>
        import(
          "../Admin/Pages/Configuration/PromoCode/PromoCodeAmend.vue"
        )
    },
    //Page menu
    {
      path: "/page-menu",
      name: "pagemenu",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/PageMenu/Index.vue")
    },
    {
      path: "/page-menu.amend",
      name: "pagemenu.amend",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/PageMenu/PageMenuAmend.vue")
    },

    //Home page setup
    {
      name: "home-page-setup",
      path: "/home-page-setup",
      beforeEnter: guardMyroute,
      component: () =>
        import(
          "../Admin/Pages/Configuration/HomePageSetup/Index.vue"
        )
    },
    {
      name: "home-page-setup.amend",
      path: "/home-page-setup.amend",
      beforeEnter: guardMyroute,
      component: () =>
        import(
          "../Admin/Pages/Configuration/HomePageSetup/HomePageSetupAmend.vue"
        )
    },
    //Image uploads
    {
      name: "imageuploads",
      path: "/imageuploads",
      beforeEnter: guardMyroute,
      component: () =>
        import(
          "../Admin/Pages/Configuration/ImageUploads/Index.vue"
        )
    },
    {
      name: "imageuploads.amend",
      path: "/imageuploads.amend",
      beforeEnter: guardMyroute,
      component: () =>
        import(
          "../Admin/Pages/Configuration/ImageUploads/ImageUploadsAmend.vue"
        )
    },
    //Feedback report
    {
      name: "feedback-questions",
      path: "/feedback-questions",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/Feedback/Index.vue")
    },
    {
      name: "feedback-questions.amend",
      path: "/feedback-questions.amend",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/Feedback/FeedbackAmend.vue")
    },
    //Form Headers
    {
      name: "form-header",
      path: "/form-header",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/FormHeaders/Index.vue")
    },
    //vehicle breakdown
    {
      name: "vehicle-breakdown",
      path: "/vehicle-breakdown",
      beforeEnter: guardMyroute,
      component: () =>
        import(
          "../Admin/Pages/Configuration/VehicleBreakdown/Index.vue"
        )
    },
    {
      name: "vehicle-breakdown-view",
      path: "/vehicle-breakdown-view",
      beforeEnter: guardMyroute,
      component: () =>
        import(
          "../Admin/Pages/Configuration/VehicleBreakdown/VehicleBreakdownView.vue"
        )
    },

    //Collection and Drop off Request
    {
      path: "/collection-and-dropoff-request",
      name: "collection-and-dropoff-request",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/CollectionAndDropoff/Index.vue")
    },
    {
      path: "/collection-and-dropoff-request.amend",
      name: "collection-and-dropoff-request.amend",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/CollectionAndDropoff/CollectionAndDropoffAmend.vue")
    },
    //Lease Calculator
    {
      name: "lease-calculator",
      path: "/lease-calculator",
      beforeEnter: guardMyroute,
      component: () =>
        import(
          "../Admin/Pages/Configuration/LeaseCalculator/Index.vue"
        )
    },
    {
      name: "lease-calculator.amend",
      path: "/lease-calculator.amend",
      beforeEnter: guardMyroute,
      component: () =>
        import(
          "../Admin/Pages/Configuration/LeaseCalculator/LeaseCalculatorAmend.vue"
        )
    },
    //Lease Enquiry
    {
      name: "lease-enquiries",
      path: "/lease-enquiries",
      beforeEnter: guardMyroute,
      component: () =>
        import(
          "../Admin/Pages/Configuration/LeaseEnquiry/Index.vue"
        )
    },
    {
      name: "lease-enquiries-view",
      path: "/lease-enquiries-view",
      beforeEnter: guardMyroute,
      component: () =>
        import(
          "../Admin/Pages/Configuration/LeaseEnquiry/LeaseEnquiryView.vue"
        )
    },
    //Contact Us Enquiry
    {
      name: "contact-us-enquiry",
      path: "/contact-us-enquiry",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/ContactUsEnquiry/Index.vue")
    },

    //Fleet Master Details
    {
      path: "/vehicle-fleet-master",
      name: "vehicle-fleet-master",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/VehicleFleetMaster/Index.vue")
    },
    {
      path: "/vehicle-fleet-master.amend",
      name: "vehicle-fleet-master.amend",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/VehicleFleetMaster/FleetMasterAmend.vue")
    },
    {
      path: "/fleet-master-details",
      name: "fleet-master-details",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/VehicleFleetMaster/FleetChildDetails.vue")
    },
    {
      path: "/fleet-master-images",
      name: "fleet-master-images",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/VehicleFleetMaster/FleetMasterImages.vue")
    },
    {
      path: "/fleet-master-location",
      name: "fleet-master-location",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/VehicleFleetMaster/FleetMasterLocation.vue")
    },
    {
      path: "/fleet-master-odometer",
      name: "fleet-master-odometer",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/VehicleFleetMaster/FleetMasterOdometer.vue")
    },
    {
      path: "/fleet-damage-marking",
      name: "fleet-damage-marking",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/VehicleFleetMaster/FleetDamageMarking.vue")
    },

    //ATM Packages 
    {
      name: "atm-package",
      path: "/atm-package",
      beforeEnter: guardMyroute,
      component: () =>
        import(
          "../Admin/Pages/Configuration/AtmPackages/Index.vue"
        )
    },
    {
      name: "atm-package.amend",
      path: "/atm-package.amend",
      beforeEnter: guardMyroute,
      component: () =>
        import(
          "../Admin/Pages/Configuration/AtmPackages/AtmPackageAmend.vue"
        )
    },
    //customer survey Details 
    {
      name: "customer-survey-details",
      path: "/customer-survey-details",
      beforeEnter: guardMyroute,
      component: () =>
        import(
          "../Admin/Pages/Configuration/CustomerSurveyDetails/Index.vue"
        )
    },
    {
      name: "customer-survey-answeres",
      path: "/customer-survey-answeres",
      beforeEnter: guardMyroute,
      component: () =>
        import(
          "../Admin/Pages/Configuration/CustomerSurveyDetails/SurveyAnsweres.vue"
        )
    },
    // Vehicle Special Pricing
    {
      path: "/vehicle-special-pricing",
      name: "vehicle-special-pricing",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/VehicleDetails/VehicleSpecialPricing.vue")
    },
    {
      path: "/vehicle-special-pricing.amend",
      name: "vehicle-special-pricing.amend",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/VehicleDetails/VehicleSpecialPricingAmend.vue")
    },
    //Bookings
    {
      name: "bookings",
      path: "/bookings",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/Bookings/Index.vue")
    },
    {
      path: "/booking-details",
      name: "booking-details",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/Bookings/BookingDetails.vue")
    },
    //Rental Extension
    {
      name: "rental-extensions",
      path: "/rental-extensions",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/RentalExtension/Index.vue")
    },
    //Minileasing
    {
      name: "mini-leasing",
      path: "/mini-leasing",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/MiniLeasing/Index.vue")
    },
    // Reservations
    {
      path: "/reservations",
      name: "reservations",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/Reservations/Index.vue")
    },
    {
      path: "/reservations.amend",
      name: "reservations.amend",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/Reservations/ReservationAmend.vue")
    },
    {
      path: "/view-available-vehicles",
      name: "view-available-vehicles",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/Reservations/ViewAvailableVehicles.vue")
    },
    {
      path: "/vehicle-return",
      name: "vehicle-return",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/Reservations/VehicleReturn.vue")
    },
    //Configure Payment
    {
      name: "configure-payment",
      path: "/configure-payment",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/ConfigurePayment/Index.vue")
    },
    {
      path: "/configure-payment.amend",
      name: "configure-payment.amend",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/ConfigurePayment/ConfigurePaymentAmend.vue")
    },
    // Hire Agreement
    {
      path: "/agreement-view",
      name: "agreement-view",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/Reservations/HireAgreement.vue")
    },
    // Invoice
    {
      path: "/invoice-view",
      name: "invoice-view",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/Reservations/InvoiceView.vue")
    },
    // Page Menu Content
    {
      path: "/page-content",
      name: "page-content",
      meta: { layout: "userpages" },
      component: () => import("../Frontend/Pages/HomePage/PageMenuContent.vue")
    },
    // Car Rental Page
    {
      path: "/car-rental",
      name: "car-rental",
      meta: { layout: "userpages" },
      component: () => import("../Frontend/Pages/CarRentals/CarRentals.vue")
    },
    {
      path: "/car-details",
      name: "car-details",
      meta: { layout: "userpages" },
      component: () => import("../Frontend/Pages/CarRentals/CarDetails.vue")
    },
    {
      path: "/booking-stripe-payment",
      name: "booking-stripe-payment",
      meta: { layout: "userpages" },
      component: () => import("../Frontend/Pages/BookingStripePayment.vue")
    },
    //Frontend Dashboard page
    {
      path: "/user-dashboard",
      name: "user-dashboard",
      meta: { layout: "userpages" },
      beforeEnter: guardMyroute,
      component: () => import("../Frontend/Pages/Dashboard/Dashboard.vue")
    },
    //frontend user profile page
    {
      path: "/my-profile",
      name: "my-profile",
      meta: { layout: "userpages" },
      beforeEnter: guardMyroute,
      component: () => import("../Frontend/Pages/Dashboard/MyProfile.vue")
    },
    //frontend user KYC page
    {
      path: "/my-kyc",
      name: "my-kyc",
      meta: { layout: "userpages" },
      beforeEnter: guardMyroute,
      component: () => import("../Frontend/Pages/Dashboard/MyKYC.vue")
    },
    //frontend user bookings page
    {
      path: "/my-bookings",
      name: "my-bookings",
      meta: { layout: "userpages" },
      beforeEnter: guardMyroute,
      component: () => import("../Frontend/Pages/Dashboard/MyBookings.vue")
    },
    //frontend nearby counters page
    {
      path: "/nearby-counters",
      name: "nearby-counters",
      meta: { layout: "userpages" },
      beforeEnter: guardMyroute,
      component: () => import("../Frontend/Pages/Dashboard/NearbyCounters.vue")
    },
    //frontend complaints and enquiries page
    {
      path: "/complaints-enquiry",
      name: "complaints-enquiry",
      meta: { layout: "userpages" },
      beforeEnter: guardMyroute,
      component: () => import("../Frontend/Pages/Dashboard/MyComplaintsEnquiries.vue")
    },
    //frontend add complaint and enquiry page
    {
      path: "/add-complaints-enquiry",
      name: "add-complaints-enquiry",
      meta: { layout: "userpages" },
      beforeEnter: guardMyroute,
      component: () => import("../Frontend/Pages/Dashboard/AddComplaintEnquiry.vue")
    },
    //frontend KYC page
    {
      path: "/add-kyc",
      name: "add-kyc",
      meta: { layout: "userpages" },
      beforeEnter: guardMyroute,
      component: () => import("../Frontend/Pages/Dashboard/AddKYC.vue")
    },
    //frontend my service booking listing
    {
      path: "/my-service-bookings",
      name: "my-service-bookings",
      meta: { layout: "userpages" },
      beforeEnter: guardMyroute,
      component: () => import("../Frontend/Pages/Dashboard/MyServiceBookings.vue")
    },
    //frontend  book a service booking create and amend
    {
      path: "/add-service",
      name: "add-service",
      meta: { layout: "userpages" },
      beforeEnter: guardMyroute,
      component: () => import("../Frontend/Pages/Dashboard/AddService.vue")
    },
    //frontend my vehicle collection and dropoff listing
    {
      path: "/my-vehicle-collection-dropoff-request",
      name: "my-vehicle-collection-dropoff-request",
      meta: { layout: "userpages" },
      beforeEnter: guardMyroute,
      component: () => import("../Frontend/Pages/Dashboard/MyVehicleCollectionDropoffRequest.vue")
    },
    //frontend  my vehicle collection and dropoff create and amend
    {
      path: "/add-collection-dropoff-request",
      name: "add-collection-dropoff-request",
      meta: { layout: "userpages" },
      beforeEnter: guardMyroute,
      component: () => import("../Frontend/Pages/Dashboard/AddCollectionDropoffRequest.vue")
    },

    //frontend my vehicle breakdown listing
    {
      path: "/my-vehicle-breakdown",
      name: "my-vehicle-breakdown",
      meta: { layout: "userpages" },
      beforeEnter: guardMyroute,
      component: () => import("../Frontend/Pages/Dashboard/VehicleBreakdown.vue")
    },
    //frontend  my vehicle breakdown create and amend
    {
      path: "/add-vehicle-breakdown-request",
      name: "add-vehicle-breakdown-request",
      meta: { layout: "userpages" },
      beforeEnter: guardMyroute,
      component: () => import("../Frontend/Pages/Dashboard/AddVehicleBreakdownRequest.vue")
    },
    //frontend my booking details
    {
      path: "/my-booking-details",
      name: "my-booking-details",
      meta: { layout: "userpages" },
      beforeEnter: guardMyroute,
      component: () => import("../Frontend/Pages/Dashboard/MyBookingDetails.vue")
    },
    //frontend my booking details
    {
      path: "/enquiry",
      name: "enquiry",
      meta: { layout: "userpages" },
      component: () => import("../Frontend/Pages/Enquiry/Enquiry.vue")
    },

    //
    {
      path: "/booking-confirmation",
      name: "booking-confirmation-status",
      meta: { layout: "userpages" },
      beforeEnter: guardMyroute,
      component: () => import("../Frontend/Pages/CarRentals/ConfirmationStatus.vue")
    },
    //frontend user Wallet page
    {
      path: "/my-wallet",
      name: "my-wallet",
      meta: { layout: "userpages" },
      beforeEnter: guardMyroute,
      component: () => import("../Frontend/Pages/Dashboard/MyWallet.vue")
    },
    //frontend user Wallet page
    {
      path: "/survey",
      name: "survey",
      meta: { layout: "userpages" },
      // beforeEnter: guardMyroute,
      component: () => import("../Frontend/Pages/Survey/SendSurvey.vue")
    },
    //Customers FAQ's 
    {
      path: "/faq's",
      name: "faq's",
      meta: { layout: "userpages" },
      component: () => import("../Frontend/Pages/FAQ/FAQ.vue")
    },
  ]
});



