<template>
  <div :class="headerbg" class="app-header header-shadow">
    <div class="logo-src" />
    <div class="app-header__content">
      <div class="app-header-right">
        <UserArea />
      </div>
    </div>
    <div class="app-header__mobile-menu">
      <div>
        <button
          type="button"
          class="hamburger close-sidebar-btn hamburger--elastic"
          v-bind:class="{ 'is-active': isOpen }"
          @click="toggleMobile('closed-sidebar-open')"
        >
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </div>
    </div>
    <div class="app-header__menu">
      <span>
        <b-button
          class="btn-icon btn-icon-only"
          variant="primary"
          size="sm"
          v-bind:class="{ active: isOpenMobileMenu }"
          @click="toggleMobile2('header-menu-open')"
        >
          <div class="btn-icon-wrapper">
            <font-awesome-icon icon="ellipsis-v" />
          </div>
        </b-button>
      </span>
    </div>
  </div>
</template>

<script>
import UserArea from "./Header/HeaderUserArea";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faEllipsisV);
export default {
  name: "Header",
  components: {
    UserArea,
    "font-awesome-icon": FontAwesomeIcon,
  },

  data() {
    return {
      isOpen: false,
      isOpenMobileMenu: false,
      app_image_url: "",
      parameter_image_url: [],
      application_name: "",
    };
  },
  props: {
    headerbg: String,
  },
  mounted() {
    this.$eventBus.$on("app_logo", this.change_logo);
    this.fetchAppImageUrl();
  },
  methods: {
    toggleMobile(className) {
      const el = document.body;
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },

    toggleMobile2(className) {
      const el = document.body;
      this.isOpenMobileMenu = !this.isOpenMobileMenu;

      if (this.isOpenMobileMenu) {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    change_logo() {
      this.app_image_url = localStorage.getItem("App_Image_Url");
      this.fetchAppImageUrl();
    },
    fetchAppImageUrl() {
      axios
        .get(process.env.VUE_APP_API_URL_ADMIN + "fetch_image_url", {})
        .then((res) => {
          this.parameter_image_url = res.data.parameter_image;
          this.application_name = res.data.application_name;
          localStorage.setItem("Application_Name", this.application_name);

          if (this.parameter_image_url != null) {
            localStorage.setItem(
              "App_Image_Url",
              this.parameter_image_url.parameter_value
            );
            this.app_image_url = localStorage.getItem("App_Image_Url");
          } else {
            localStorage.removeItem("App_Image_Url");
            this.app_image_url = "";
          }
        })
        .catch((err) => {
          this.flashMessage.error({
            message: this.$t("something_went_wrong"),
            time: 4000,
            blockClass: "custom-block-class",
          });
          console.log("this error" + err);
        });
    },
  },
};
</script>
